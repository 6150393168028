import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import { SEO } from "../components/seo"

import igor from "../images/igor-bujas.jpeg"
import climbing from "../images/igor-bujas-climbing.jpeg"
import diving from "../images/igor-bujas-diving.jpg"
import patrik from "../images/igor-i-patrik.jpeg"
import alps from "../images/igor-bujas-alps.jpeg"
import blanc from "../images/igor-bujas-mt-blanc.jpeg"


const about = () => {
    return (
        <div>
            <Layout>
                <div className="container page-width center inner-pages">
                    <h2>About</h2>
                    <h3 class="subtitle">Welcome To The North Side.</h3>

                    <div class="about">
                        <div>
                            <h4 className='mt50'><strong>Hi, my name is Igor Bujas</strong> &amp; I am a screen designer and a front-end developer.</h4>
    
                            <p>I have worked as a web professional dating all the way back to 2003! I fixed the bugs for IE 5.5, did Flash animations and worked with Photoshop while it was still a Macromedia’s product, and CSS Zen Garden was a revolution. Yes, I am that old.</p>

                            <p>I specialise in designing visually appealing websites/user interfaces and developing them according to the W3C standards. I am a front-end developer, which means I provide client-side solutions in the manner of CSS and HTML code, spicing it up with Javascript. I’ve been working as a professional designer for the past 18 years, the last few as a partner at HarttMedia, an LA based Shopify agency.
                            </p>
                        </div>
                        <img src={igor} alt="Igor Bujas, UI designer and CSS & Shopify developer" className="about-img" />
                    </div>

                    <p>I own a small design studio located in the heart of the beautifull city of Zagreb, Croatia.  While not differentiating between the UI design and the code, I’m an avid outdoorsman, mountaineer and former paraglider pilot, CMAS certified diver and, most importantly, dating recently, a father.</p>

                    <p>I am spending less time in the wild since my boy was born, which means I do a lot of rolling at the local jiu jitsu club, and sparring at the Krav Maga academy where I work as an  instructor.</p>
                    
                    <p>North-NorthWest was established in Bristol, in one of my favorite pubs in Stokes Croft, November 2009.</p>

                    <p><small>Some boring, technical stuff: this website was designed in Adobe XD, hand-coded using CSS/JSX &amp; React, and it is running on Gatsby. Maybe I'll deploy it to Netlify. I'll let you know when I finish it. <br />
                    Even less important, but somehow the question about the setup always pops up: I'm workin on a 13" Mac. Easy to carry around, and small enough to hide from the kids. If I had a nickel for every time I've spilled coffe on it, I'd have about...7 nickels.</small></p>

                    <div class="igor-bujas">
                            <img src={climbing} alt="Igor Bujas, UI designer and CSS & Shopify developer" />
                            <div class="igor-inner-gallery">
                                <img src={diving} alt="Igor Bujas, UI designer and CSS & Shopify developer" />
                                <img src={blanc} alt="Igor Bujas, UI designer and CSS & Shopify developer" />
                            </div>
                            <img src={patrik} alt="Igor Bujas, UI designer and CSS & Shopify developer" />
                            <img src={alps} alt="Igor Bujas, UI designer and CSS & Shopify developer" />
                    </div>                      

                </div>     
                              
            </Layout>
        </div>
    )
}

export default about

export const Head = () => (
    <SEO />
  )